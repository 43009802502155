import logo from './logo.svg';
import './App.css';
import  {Map} from "./Map/Map"
import Legend from "./Legend"
import "leaflet/dist/leaflet.css"

function App() {
  return (
    <>
   <Map/>
   <Legend/>
   </> 
  );
}

export default App;
